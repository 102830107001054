<template>
  <div class="category">
    <section class="category-option">
      <el-button type="primary" @click="openInsertCategoryDialog">
        新增分类
      </el-button>
    </section>
    <section v-if="!categorys.length">
      <el-empty :image-size="200" description="一个分类都没有"></el-empty>
    </section>
    <section v-else class="categorys">
      <el-table :data="categorys" style="width: 100%;">
        <el-table-column label="背景">
          <template slot-scope="scope">
            <img class="category-bg" :src="scope.row.url" />
          </template>
        </el-table-column>
        <el-table-column label="标题">
          <template slot-scope="scope">
            <span>{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column label="副标题">
          <template slot-scope="scope">
            <span>{{ scope.row.subtitle }}</span>
          </template>
        </el-table-column>
        <el-table-column label="商品管理">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleGoGoodsList(scope.row.id)">
              商品管理
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="编辑">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">
              编辑
            </el-button>
            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="不用了"
              icon="el-icon-info"
              icon-color="red"
              title="确定删除分类吗？"
              @confirm="handleDelete(scope.$index, scope.row)"
            >
              <el-button slot="reference" size="mini" type="danger">
                删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
        <el-table-column label="排序">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleMoveUp(scope.$index, scope.row)"
              :disabled="scope.$index == 0"
            >
              上移
            </el-button>
            <el-button
              size="mini"
              @click="handleMoveDown(scope.$index, scope.row)"
              :disabled="scope.$index == categorys.length - 1"
            >
              下移
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>

    <el-dialog
      :title="editMode ? '修改分类' : '新增分类'"
      :visible.sync="insertCategoryDialogVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="closeInsertCategoryDialog"
    >
      <section>
        <h3>分类图片</h3>
        <el-upload
          drag
          :show-file-list="false"
          :on-success="handleUploadSuccess"
          :before-upload="handleUploadBefore"
          :action="UPLOAD_IMG_URL"
          multiple
        >
          <img v-if="categoryUrl" :src="categoryUrl" class="upload-img" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </section>
      <section>
        <h3>分类标题</h3>
        <el-input v-model.trim="categoryTitle" placeholder="请输入分类标题"></el-input>
      </section>
      <section>
        <h3>分类小标题</h3>
        <el-input v-model="categorySubTitle" placeholder="请输入分类小标题"></el-input>
      </section>
      <span slot="footer" class="dialog-footer">
        <el-button @click="insertCategoryDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="saveCategory">
          {{ editMode ? '修 改' : '保 存' }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  UPLOAD_IMG_URL,
  reqGetAllCategory,
  reqInsertCategory,
  reqUpdateCategory,
  reqDeleteCategory,
  reqMoveCategory,
} from '@/api'

export default {
  name: 'Category',
  components: {},
  data() {
    return {
      UPLOAD_IMG_URL,
      insertCategoryDialogVisible: false,
      categorys: [],

      categoryId: '',
      categoryUrl: '',
      categoryTitle: '',
      categorySubTitle: '',
      editMode: false,
    }
  },
  mounted() {
    this.getAllCategory()
  },
  methods: {
    handleEdit(index, v) {
      this.insertCategoryDialogVisible = true
      this.editMode = true
      this.categoryId = v.id
      this.categoryUrl = v.url
      this.categoryTitle = v.title
      this.categorySubTitle = v.subtitle
    },
    handleDelete(index, row) {
      reqDeleteCategory(row.id)
        .then((res) => {
          this.$message.success(res.msg)
          this.getAllCategory()
        })
        .catch((err) => {
          this.$message.error(err)
        })
        .finally(() => {})
    },
    getAllCategory() {
      reqGetAllCategory()
        .then((res) => {
          this.categorys = res.data
        })
        .catch((err) => {
          this.$message.error(err)
        })
        .finally(() => {})
    },
    openInsertCategoryDialog() {
      this.insertCategoryDialogVisible = true
    },
    closeInsertCategoryDialog() {
      this.editMode = false
      this.categoryId = ''
      this.categoryUrl = ''
      this.categoryTitle = ''
      this.categorySubTitle = ''
    },
    handleUploadSuccess(res, file) {
      if (res.code === 20051) {
        this.categoryUrl = res.data
      }
    },
    handleUploadBefore(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    saveCategory() {
      if (!this.categoryTitle) {
        this.$message.error('必须填写标题')
        return
      }
      if (this.editMode) {
        reqUpdateCategory({
          id: this.categoryId,
          url: this.categoryUrl,
          title: this.categoryTitle,
          subtitle: this.categorySubTitle,
        })
          .then((res) => {
            this.$message.success(res.msg)
            this.getAllCategory()
          })
          .catch((err) => {
            this.$message.error(err)
          })
          .finally(() => {
            this.insertCategoryDialogVisible = false
          })
      } else {
        reqInsertCategory({
          url: this.categoryUrl,
          title: this.categoryTitle,
          subtitle: this.categorySubTitle,
        })
          .then((res) => {
            this.$message.success(res.msg)
            this.getAllCategory()
          })
          .catch((err) => {
            this.$message.error(err)
          })
          .finally(() => {
            this.insertCategoryDialogVisible = false
          })
      }
    },
    editCategory(v) {
      this.insertCategoryDialogVisible = true
      this.editMode = true
      this.categoryId = v.id
      this.categoryUrl = v.url
      this.categoryTitle = v.title
      this.categorySubTitle = v.subtitle
    },
    handleGoGoodsList(id) {
      this.$router.push({ path: '/goods', query: { id: id } })
    },
    delCategory(v) {},
    handleMoveUp(index, v) {
      //1.更新视图
      if (index == 0) {
        return
      }
      this.categorys.splice(
        index - 1,
        1,
        ...this.categorys.splice(index, 1, this.categorys[index - 1]),
      )
      //2.更新数据库
      reqMoveCategory([
        {
          id: this.categorys[index].id,
          orderNum: index,
        },
        {
          id: this.categorys[index - 1].id,
          orderNum: index - 1,
        },
      ])
        .then((res) => {
          this.$message.success(res.msg)
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    handleMoveDown(index, v) {
      //1.更新视图
      if (index == this.categorys.length - 1) {
        return
      }
      this.categorys.splice(
        index + 1,
        1,
        ...this.categorys.splice(index, 1, this.categorys[index + 1]),
      )
      //2.更新数据库
      reqMoveCategory([
        {
          id: this.categorys[index].id,
          orderNum: index,
        },
        {
          id: this.categorys[index + 1].id,
          orderNum: index + 1,
        },
      ])
        .then((res) => {
          this.$message.success(res.msg)
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
  },
}
</script>

<style lang="scss">
$img-width: 88px;

.category {
  width: 100%;
  background-color: $def-content-bg-color;
  border-radius: $def-radius;
  padding: $def-margin * 4;
  position: relative;
  .category-option {
    display: flex;
    justify-content: flex-end;
  }

  .el-dialog {
    max-width: 400px;
    .el-upload,
    .el-upload-dragger {
      width: $img-width;
      height: $img-width;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .el-dialog__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    section {
      width: 100%;
      margin-bottom: 16px;

      .el-input {
        width: 100%;

        input {
          width: 100%;
        }
      }
    }
  }

  .categorys {
    .category-bg {
      width: 88px;
      height: 88px;
    }
    ul {
      li {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.06);
        img {
          width: 88px;
          height: 88px;
        }
        span {
          flex: 1;
          text-align: center;
        }
        a {
          color: rgb(40, 90, 253);
          font-size: 14px;
          cursor: pointer;
          text-decoration: underline;
          margin-left: 20px;
        }
        .category-item-options {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
</style>
